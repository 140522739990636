import MobileDetect from "mobile-detect";

export type UAType = "ios" | "android" | "other";

const DEBUG_TYPE: UAType | null = null;

export function getUserAgentType(_window: Window): UAType {
  if (!_window) throw new Error(`Invalid call`);
  if (DEBUG_TYPE) return DEBUG_TYPE;
  const md = new MobileDetect(_window.navigator.userAgent);
  if (md.os() === "iOS") return "ios";
  if (md.os() === "AndroidOS") return "android";
  return "other";
}
